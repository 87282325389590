<template>
  <div>
    <div class="chargeset-box" v-loading="loading">
      <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%" editable @edit="tabEdit">
        <el-tab-pane v-for="item in tablist" :key="item.type" :label="item.type_name" :name="item.key">
          <child @submitSet="submitSet(item)" @deleted="tabEdit" :ref="item.key" :value="item.child" :data="item">
          </child>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div style="color: red;font-size: 12px;">
      <p>说明:</p>
      <p>1、按用量计费:商户日常费用按用量进行计算，计算单价以设置单价为准</p>
      <p>2、固定费用收费:商户固定费用按设定费用计算，两种费用可以多选</p>
      <p> 3、查表日期:设置后，到了当日才可以通过APP进行查表</p>
      <p>4、出账确认日期:APP查表上传数据后需要管理员进行确认后出账，这里为后台确认日期</p>
      <p>5、收费间隔:每次收取费用的间隔时间</p>
      <p> 6、新增加的费用需要重新初始化之后才会显示在商户日常费用账单之中，删除或停用需该项费用不存在欠费时才可进行</p>
    </div>
    <!-- <MyButton @click="addcharge">增加其他收费</MyButton> -->
    <Confirm type="slot" ref="myconfirm" @confirm="addSetName" :disabled="!ohterName">
      <template slot="content">
        <el-form label-width="100px">
          <el-form-item label="增加其他费用">
            <el-input style="width: 150px" v-model.trim="ohterName" placeholder="输入其他费用名字"></el-input>
            <div v-show="errorshow" style="color: red; font-size: 11px; height: 15px">
              请输入名字
            </div>
          </el-form-item>
        </el-form>
      </template>
    </Confirm>
  </div>
</template>
<script>
import child from "./daychargesetchild/child.vue";
import Confirm from "../../../unit/components/confirm.vue";
import { json } from "body-parser";
export default {
  components: {
    child,
    Confirm
  },
  data () {
    return {
      activeName: "daily10",
      data: null,
      tablist: [],
      tabId: "",
      property_setting_id: "",
      ohterName: "",
      errorshow: false,
      dataList: [],
      dataValue: {
        unit_price: "",
        company: "",
        query_time: "",
        confirm_time: "",
        charging_interval: "",
        fixed_charges: "0",
        usage_charge: "0",
      },
      describeName: '水费设置',
      loading: false
    };
  },
  created () {
    this.getDeatail("waterSetting");
    this.getTablist();
  },
  methods: {
    handleClick (e) {
      this.describeName = e.label;
      this.getDeatail(e.name);
    },
    async getTablist () {
      this.loading = true;
      try {
        let {
          data: res
        } = await this.$request.HttpGet("/setting/dailySetting");
        this.loading = false
        if (res) {
          this.tabId = res.property_setting_id;
          res.values_struct.forEach((item) => {
            item.edit = true;
            item.child = item.children.values_struct;
          });
          this.data = res;
          this.tablist = res.values_struct;
        }
      } catch {
        this.loading = false
      }

    },
    async getDeatail () {
      // let { data: res } = await this.$request.HttpGet(
      //   `/setting/edit?key=${type}`
      // );
      // this.dataValue = JSON.parse(res.value);
    },
    panduan (obj) {
      return this.$request.HttpGet('/setting/queryDelete', obj)
    },
    async tabEdit (target) {
      if (!this.$common.hasRole(95716)) return this.$rmessage("暂无权限!")
      let arr = ["daily10", "daily20", "daily30", "loss_price"];
      if (target) {
        let obj = {
          key: 'daily',
          values: []
        };

        if (arr.includes(target)) return this.$message({ message: "该项不能删除", type: "warning" })
        let keyType = {
          key: target,
          type: ''
        }
        for (let i = 0; i < this.tablist.length; i++) {
          if (this.tablist[i].key == target) {
            keyType.type = this.tablist[i].type
            break
          }
        }
        let re = await this.panduan(keyType)
        if (re.data) {
          this.$message({ message: '当前费用有商户存在欠费，无法删除，请收费完成后在进行此操作！', type: 'error' });
        } else {
          this.$myconfirm('该数据将永久删除，确认删除？').then(() => {
            this.tablist.forEach((item, i) => {
              if (item.key == target) {
                this.tablist.splice(i, 1);
                if (this.activeName == target) {
                  this.activeName = this.tablist[i - 1].key;
                }
              } else {
                obj.values.push({
                  type: item.type,
                  key: item.key,
                  type_name: item.type_name
                })
              }
            });
            this.submitSet(obj, true)
          })
          return false
        }



      } else {
        if (this.$common.hasRole(56306)) {
          this.$refs.myconfirm.title = "增加其他收费";
          this.$refs.myconfirm.fade();
        } else {
          this.$message({ message: '暂无权限!', type: 'error' })
        }

      }
    },
    async addSetName () {
      if (!this.ohterName) return (this.errorshow = true);
      this.$request.HttpPost('/setting/dailySettingAdd', {
        describe: this.ohterName
      })
        .then(async res => {
          this.describeName = this.ohterName
          this.ohterName = ''
          this.$common.notifySuccess(res.msg);
          await this.getTablist();
          this.activeName = this.tablist[this.tablist.length - 1].key

        })
    },
    submitSet (data, deleted) {
      data.values = JSON.stringify(data.values)
      let form = deleted ? data : {
        key: data.key,
        describe: this.describeName,
        values: data.children.values_struct
      }
      if (typeof form.values == 'object') {
        // form.values = JSON.parse(form.values);
        for (let i in form.values) {
          if (i !== 'company') {
            form.values[i] = Number(form.values[i])
          }
        }
      }
      this.$request.HttpPost('/setting/add', form).then(res => {
        this.$common.notifySuccess("设置成功");
      })
    },
  },
};
</script>
<style lang="scss">
.chargeset-box {
  display: inline-flex;
}
</style>
