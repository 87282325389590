<template>
  <div>
    <el-form label-width="100px" label-position="right">
      <el-form-item :label="setType">
        <el-input :placeholder="`请输入${setType}`" style="width: 202px" v-model="value.unit_price" type="number" @blur="minvalue">
          <template slot="append">
            <span>元</span>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="费用单位">
        <el-input v-model="value.company" placeholder="请输入费用单位" style="width: 202px" :disabled="defaultSetable(data.type)">
        </el-input>
      </el-form-item>
      <el-form-item label="损耗单价">
        <el-input v-model="value.loss_price" placeholder="请输入损耗单价" style="width: 202px" type="number" v-num="2">
        </el-input>
      </el-form-item>
      <el-form-item label="查表日期">
        <el-select v-model="value.query_time" placeholder="">
          <el-option v-for="item in 31" :key="item" :value="item" :label="`${item}号`"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出账确认日期">
        <el-select v-model="value.confirm_time" placeholder="">
          <el-option v-for="item in 31" :key="item" :value="item" :label="`${item}号`"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收费间隔">
        <el-select v-model="value.charging_interval" placeholder="">
          <el-option v-for="item in 12" :value="item" :key="item" :label="`${item}个月`"></el-option>
          <!-- <el-option :value="2" label="半年"></el-option>
          <el-option :value="3" label="一年"></el-option> -->
        </el-select>
      </el-form-item>
      <el-form-item label="收费方式">
        <el-checkbox label="按用量计费" v-model="usageCharge" @change="checked($event, ' usage_charge')"></el-checkbox>
        <el-checkbox label="固定费用收费" v-model="fixedCharge" @change="checked($event, 'fixed_charges')"></el-checkbox>
      </el-form-item>
      <el-form-item>
        <MyButton type="error" @click="changeSet(1)" v-if="value.deactivate == '0' && [10, 20, 30].includes(data.type)">停用</MyButton>
        <MyButton @click="changeSet(0)" v-else-if="
            value.deactivate == '1' && [10, 20, 30].includes(data.type)
          ">启用</MyButton>
        <MyButton type="error" @click="deleted" v-else>删除</MyButton>
        <MyButton left :disabled="isAble" @click="submit">设置</MyButton>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "chargechild",
  props: {
    data: {
      type: Object,
    },
    value: {
      type: Object,
    },
  },
  data () {
    return {
      usage_charge: false,
      fixed_charges: false,
      form: {
        describe: "",
        values: {
          unit_price: "",
          query_time: "",
          confirm_time: "",
          charging_interval: "",
          usage_charge: true,
          type: "",
          fixed_charges: "",
          loss_price: ''
        },
      },
    };
  },
  created () { },
  computed: {
    setType () {
      switch (this.data.type) {
        case 10:
          return "水费单价";
        case 20:
          return "电费单价";
        case 30:
          return "气费单价";
        default:
          return "费用单价";
      }
    },
    usageCharge: {
      set (value) {
        if (value) {
          this.value.usage_charge = 1;
        } else {
          this.value.usage_charge = 0;
        }
      },
      get () {
        if (this.value.usage_charge == 0) {
          return false;
        }
        return true;
      },
    },
    fixedCharge: {
      set (value) {
        // this.usage_charge = value
        if (value) {
          this.value.fixed_charges = 1;
        } else {
          this.value.fixed_charges = 0;
        }
      },
      get () {
        if (this.value.fixed_charges == 0) {
          return false;
        }
        return true;
      },
    },
    isAble () {
      let isable = false;
      for (let i in this.value) {
        if (this.value[i] === "") {
          isable = true;
        }
      }
      return isable;
    },
  },
  methods: {
    changeSet (type) {
      if (type == 0) {
        this.value.deactivate = type;
        this.submit();
      } else {
        if (this.$common.hasRole(33438)) {
          this.$myconfirm(
            "停用之后收费列表中将隐藏该项费用，已产生费用的数据将会清除，重新启用之后需重新初始化！"
          ).then(() => {
            this.value.deactivate = type;
            this.submit();
          });
        } else {
          this.$rmessage("暂无权限!");
        }
      }
    },
    submit (type) {
      if (type) {
        if (this.$common.hasRole(92670)) {
          for (let i in this.form.values) {
            if (i !== 'company') {
              this.form.values[i] = Number(this.form.values[i])
            }
          }
          return this.$emit("submitSet", this.form);
        }
        return this.$rmessage("暂无权限!");
      }
      this.$emit("submitSet", this.form);
    },
    checked (e, type) {
      this[type] = e;
    },
    deleted () {
      if (this.$common.hasRole(95716)) {
        this.$myconfirm(
          "停用之后收费列表中将隐藏该项费用，已产生费用的数据将会清除，重新启用之后需重新初始化！"
        ).then(() => {
          this.$emit("deleted", this.data.key);
        });
      } else {
        this.$rmessage("暂无权限!");
      }
    },
    defaultSetable (e) {
      if ([10, 20, 30].includes(e)) {
        return true;
      }
      return false;
    },
    minvalue () {
      if (this.value.unit_price <= 0) {
        this.$rmessage("单价不能小于等于0", "error", "提示");
        this.value.unit_price = 1;
      }
    },
  },
};
</script>
<style lang='scss'>
</style>
